import React, { Component } from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import { Button } from '@paljs/ui/Button';
const isSSR = typeof window === 'undefined';
const CKEditor = !isSSR ? require('ckeditor4-react') : <div />;
//const ClassicEditor = !isSSR ? require('@ckeditor/ckeditor5-build-classic') : <div />;

class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorData: '',
      imgFile: null,
      imgUrl: '',
    };
  }

  saveData = () => {
    this.props.saveEditor(this.state.editorData);
  };

  saveImage = () => {
    this.props.saveImg(this.state.imgFile);
  };

  handleFile = (file) => {
    if (file) {
      this.setState({ imgFile: file, imgUrl: URL.createObjectURL(file) });
    }
  };

  handlePaste = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let items = (e.clipboardData || e.originalEvent.clipboardData).items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind == 'file') {
        let temp = items[i].getAsFile();
        this.handleFile(temp);
        break;
      }
    }
  };

  handleClose = () => {
    this.setState({ imgFile: null, imgUrl: '' });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        className={{
          base: 'modal-base',
          afterOpen: 'modal-base_after-open',
          beforeClose: 'modal-base_before-close',
        }}
        onAfterClose={this.handleClose}
        portalClassName={'data-input-modal'}
      >
        <div className="modal-body">
          {this.props.type == 'editor' && (
            <div>
              {!isSSR && (
                <CKEditor
                  data={this.props.data}
                  type="classic"
                  config={{ allowedContent: true, contentsCss: '/ck.css' }}
                  onChange={(event) => {
                    this.setState({ editorData: event.editor.getData() });
                  }}
                />
              )}
            </div>
          )}

          {this.props.type == 'image' && (
            <div onPaste={this.handlePaste}>
              <Dropzone onDrop={(acceptedFiles) => this.handleFile(acceptedFiles[0])} multiple={false} accept="image/*">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p className="drag-area">Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div className="or-wrap">
                <span>OR</span>
              </div>
              <div className="paste-img">
                Press <strong>Ctrl+V</strong> to paste a screenshot from the clipboard
              </div>
              <img src={this.state.imgUrl} width="150px" />
            </div>
          )}

          <div className="modal-base-footer mt-2 text-right">
            {this.props.isImgLoader ? (
              <div className="spinner-border text-primary"></div>
            ) : (
              <div>
                <Button className="mr-2" status="Danger" onClick={this.props.close}>
                  Close
                </Button>
                {this.props.type == 'image' ? (
                  <Button onClick={this.saveImage} disabled={!this.state.imgFile}>
                    Upload
                  </Button>
                ) : (
                  <Button onClick={this.saveData}>Save</Button>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default PopUp;
